<div class="loginWrap">
  <div class="loginInner">
    <div class="mClose text-right">
      <button class="btn shadow-none p-1 bg-transparent text-dark " (click)="closeDialog()">
        <i class="material-icons m-0 align-middle">close</i>
      </button>
    </div>
    <div class="modalInner" [formGroup]="forgotForm">
      <div class="logoOnModal text-center mb-5"><img src="assets/images/vendorLogo.png" alt=""></div>
      <h2>Set New Password</h2>

      <div class="w-100 mb-sm-4">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Password</mat-label>
          <input matInput type="password" #x placeholder="********" formControlName="password">
          <!-- <button class="eyebtn " (click)="x.type=x.type=='password'?'text':'password'"><i
            class="material-icons">visibility</i></button> -->
          <mat-error *ngIf="forgotForm.controls.password.touched && forgotForm.controls.password.hasError('required')">
            This is required
          </mat-error>
          <mat-error *ngIf="forgotForm.controls.password.touched && forgotForm.controls.password.hasError('minlength')">
            Password must be at least 6 characters
          </mat-error>
          <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
        <div class="col-md-12 mb-5">
          <button class="d-block mx-auto btn btn-deep" (click)="updatePassword();">Update Password</button>
        </div>
      </div>
      <!-- <p class="lastLine text-center">
        Don’t have an account? <a (click)="onClickOpenRegister()">Sign Up</a>
      </p> -->
    </div>
  </div>
</div>
<div class="headerVendor">
    <h1>View Template</h1>
    <app-vendor-header></app-vendor-header>
</div>

<div class="addTemplate">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Subject</mat-label>
                <input matInput disabled [value]="details?.subject">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Sender</mat-label>
                <input matInput disabled [value]="details?.sender?.email">
            </mat-form-field>
        </div>
        <div class="col-md-12">
            <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Mail Body</mat-label>
                <textarea matInput style="display: none;"></textarea>
                <p [innerHTML]="details?.htmlContent" style="margin-top: 30px;"></p>
            </mat-form-field>
        </div>
        <button (click)="sendMail()">Send</button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanDeactivate } from '@angular/router';
import { DirtyComponent } from '@project/models/dirty-component';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isLoggedIn: boolean;
  constructor(
    private event: EventService,
    private router: Router,
    private api: ApiService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.event.isLogin.subscribe((res: boolean): any => {
      if (!res) {
        setTimeout(() => {
          this.rederectToURL(this.router.url);
        }, 100);
      } else {
        return true;
      }
    });
    return true;
  }

  private rederectToURL(URL: string): any {
    return this.event.setTempEmmit({ rederectURL: URL }).then(() => {
      this.router.navigate(['/login']);
    });
  }

}

// To Check form unsaved or not

@Injectable({
  providedIn: 'root'
})
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {

  constructor(private api: ApiService) { }

  canDeactivate(
    component: DirtyComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()) {
      return this.api.alertModal('Are you sure?', 'There are changes you have made to the page. If you quit, you will lose your changes.', 'warning', true, true).then((result: any) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });
      // return confirm('There are changes you have made to the page. If you quit, you will lose your changes.');
    } else {
      return true;
    }
  }

}

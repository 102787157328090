import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {

  addContactForm: FormGroup;
  id: any;
  contactData: any;
  contactDetails: any;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    public router: Router,
    public ar: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ar.params.subscribe((pr: any) => {
      this.id = pr.id;
    })
    this.getDetails();
    this.addContactForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
  }

  getDetails() {
    this.api.get(`/vendorportal/sendinblue/contact-details/${this.id}`).subscribe(res => {
      this.contactDetails = res.data;
      this.addContactForm.controls.email.setValue(this.contactDetails.email)
      this.addContactForm.controls.firstName.setValue(this.contactDetails.attributes.FIRSTNAME)
      this.addContactForm.controls.lastName.setValue(this.contactDetails.attributes.LASTNAME)
    }, err => {
     this.api.alert(err.message, 'error');
    })
 }



 addContact() {
    if(this.addContactForm.valid){
      if(this.contactDetails.email === this.addContactForm.value.email){
        const data = {
          id: this.id,
          firstName: this.addContactForm.value.firstName,
          lastName: this.addContactForm.value.lastName,
        }
        this.api.post(`/vendorportal/sendinblue/contact-update`, data).subscribe(res => {
            this.api.alert(res.message, 'success')
            this.router.navigateByUrl('/vendor/contacts')
        }, err => {
          this.api.alert(err.message, 'error')
        })
      } else {
        const data = {
          id: this.id,
          email: this.addContactForm.value.email,
          firstName: this.addContactForm.value.firstName,
          lastName: this.addContactForm.value.lastName,
        }
        this.api.post(`/vendorportal/sendinblue/contact-update`, data).subscribe(res => {
            this.api.alert(res.message, 'success')
            this.router.navigateByUrl('/vendor/contacts')
        }, err => {
          this.api.alert(err.message, 'error')
        })
      }
    } else {
      this.api.alert('Please check the field properly', 'error')
    }
  }


}

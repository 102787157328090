import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@envconfig';
import { ApiService } from '@project/services/api.service';
import { StorageService } from '@project/services/storage.service';
import { LoginComponent } from '@project/site/auth/login/login.component';

@Component({
  selector: 'app-shop-drop-landing',
  templateUrl: './shop-drop-landing.component.html',
  styleUrls: ['./shop-drop-landing.component.scss']
})
export class ShopDropLandingComponent implements OnInit {
  currentYear: number;
  pageData: any;
  baseImgPath: string;

  constructor(
    private storage: StorageService,
    public dialog: MatDialog,
    private api: ApiService
  ) {
    this.currentYear = (new Date()).getFullYear();
    this.baseImgPath = environment.IMAGE_BASE_URL + '/vendor_home_content/';
  }

  ngOnInit(): void {
    if (!this.storage.isAuthenticate()) {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '670px',
        // height: '690px',
        maxHeight: '80vh'
      });
    }
    this.getPageData();
  }

  getPageData() {
    this.api.get(`/vendorportal/cms/homecontent`).subscribe((res: any) => {
      console.log(res);
      if (res.status === 200) {
        this.pageData = res.data;
      }
    });
  }

}

<div class="headerVendor">
    <h1>Templates</h1>
    <div class="">
        <app-vendor-header></app-vendor-header>
    </div>
</div>


<div class="order-Head">
    <div class="inner">
        <h5 class="">Template Listing</h5>
        <p class="">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    </div>
</div>

<div class="orderTable">
    <div class="table-responsive">
        <button [routerLink]="['/vendor/template-add']">+ Add New Template</button>
        <table class="table">
            <thead>
                <th>Title</th>
                <th>Subject</th>
                <th>Action</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let item of templateData">
                    <td>{{item?.name}}</td>
                    <td>{{item?.subject}}</td>
                    <td>
                        <a [routerLink]="['/vendor/template-view/' + item?.id]">
                            <i class="material-icons">mail_outline</i>
                        </a>
                        <a [routerLink]="['/vendor/template-edit/' + item?.id]"><i class="material-icons">edit</i></a>
                        <a (click)="deleteTemplate(item?.id)"><i class="material-icons">delete</i></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="mainBlock">
    <div class="leftBlock d-flex flex-column">
        <div class="logo">
            <a routerLink="/"><img src="assets/images/vendorLogoDashBoard.png" alt="" class=""></a>
        </div>
        <div class="navBox h-100 overflow-auto">
            <ul class="list-unstyled">
                <li>
                    <a class="align-items-center">
                        <i class="material-icons">
                            dashboard
                        </i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li class="haveChild">
                    <a class="align-items-center" (click)="openSubmenu()">
                        <i class="material-icons">
                            library_books
                        </i>
                        <span>Product Management</span>
                    </a>
                    <ng-container *ngIf="subMenu">
                        <div class="subMenu">
                            <ul class="list-unstyled">
                                <li><a routerLink="product-listing" class="">- Listing </a></li>
                                <li><a routerLink="add-product">- Add Product </a></li>
                            </ul>
                        </div>
                    </ng-container>
                </li>
                <li>
                    <a class="align-items-center" routerLink="order-management" routerLinkActive="active">
                        <i class="material-icons">
                            library_add
                        </i>
                        <span>Order Management</span>
                    </a>
                </li>
                <li class="haveChild">
                    <a class="align-items-center" (click)="openSubmenuTwo()">
                        <i class="material-icons">
                            email
                        </i>
                        <span>Email</span>
                    </a>
                    <ng-container *ngIf="subMenuTwo">
                        <div class="subMenu">
                            <ul class="list-unstyled">
                                <li><a routerLink="templates" class="">- Templates</a></li>
                                <li><a routerLink="contacts">- Contacts</a></li>
                            </ul>
                        </div>
                    </ng-container>
                </li>
            </ul>
        </div>
    </div>
    <div class="rightBlock  d-flex flex-column">
        <div class="mobileNavToggle p-3 bg-white mx-n3   d-flex d-xl-none align-items-center justify-content-between">
            <div class="logo">
                <a class=""><img src="assets/images/vendorLogoDashBoard.png" alt="" class=""></a>
            </div>

            <div class="btnNav">
                <button class="btn px-2 py-2 btn-deep" (click)="navOpen()"><i
                        class="material-icons align-middle m-0">menu</i></button>
            </div>

        </div>
        <div class="w-100 h-100 overflow-auto addPadding">
            <router-outlet></router-outlet>
        </div>
        <div class="copyBox text-center text-xl-right">
            © {{currentYear}} Shopdrop. All Rights Reserved.
        </div>
    </div>
</div>
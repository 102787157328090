<!-- <div class="menu">
  <button type="button" appDropdown="menu">Open Menu</button>
</div> -->
<header class="header">
  <div class="headerTop">
    <div class="container">
      <p>
        <mat-icon>local_offer</mat-icon>Some offer goes here
      </p>
    </div>
  </div>
  <div class="headerBot">
    <div class="container-fluid">
      <div class="headerbotinner">

        <nav class="navbar navbar-expand-lg">
          <div class="logosec">
            <a href="/home">
              <amp-img src="./assets/images/logo.svg" alt="" height="76" width="276"></amp-img>
            </a>
          </div>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <mat-icon>menu</mat-icon>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="submenuHas"><a (click)="toggle()">Fashion</a>
                <div class="submenu" *ngIf="show">
                  <div class="submenuInner">
                    <div class="submenuItem">
                      <h3>Create </h3>
                      <div class="submenuLftWrap">
                        <div class="item">
                          <div class="menuwrap">
                            <h4>Dresses + Jumpsuits</h4>
                            <ul>
                              <li><a routerLink="#">Casual Dresses</a></li>
                              <li><a routerLink="#">Going Out + Party Dresses</a></li>
                              <li><a routerLink="#">Mini Dresses</a></li>
                              <li><a routerLink="#">Maxi + Midi Dresses</a></li>
                              <li><a routerLink="#">Jumpsuits</a></li>
                            </ul>
                          </div>
                          <div class="menuwrap">
                            <h4>Bottoms</h4>
                            <ul>
                              <li><a routerLink="#">Jeans</a></li>
                              <li><a routerLink="#">Pants</a></li>
                              <li><a routerLink="#">Shorts</a></li>
                              <li><a routerLink="#">Skirts</a></li>
                              <li><a routerLink="#">Overalls + Coveralls</a></li>
                              <li><a routerLink="#">Sweatpants</a></li>
                            </ul>
                          </div>
                        </div>
                        <div class="item">
                          <div class="menuwrap">
                            <h4>Tops</h4>
                            <ul>
                              <li><a routerLink="#">Graphic Tees</a></li>
                              <li><a routerLink="#">Shirts + Blouses</a></li>
                              <li><a routerLink="#">T Shirts</a></li>
                              <li><a routerLink="#">Camis + Tank Tops</a></li>
                              <li><a routerLink="#">Hoodies + Sweatshirts</a></li>
                              <li><a routerLink="#">Going out Tops</a></li>
                              <li><a routerLink="#"> Sweaters + Cardigans</a></li>
                              <li><a routerLink="#">Bodysuits</a></li>
                            </ul>
                          </div>
                        </div>
                        <div class="item">
                          <div class="menuwrap">
                            <h4>Jackets</h4>
                            <ul>
                              <li><a routerLink="#">Bomber Jackets</a></li>
                              <li><a routerLink="#"> Denim + Corduroy Jackets</a></li>
                              <li><a routerLink="#">Lightweight Jackets + Blazers</a></li>
                              <li><a routerLink="#">Leather + Moto Jackets</a></li>
                              <li><a routerLink="#">Fleece + Teddy Jackets</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="submenuItem">
                      <h3>Fabrics</h3>
                      <div class="menuwrap">
                        <h4>Cloth + Prints</h4>
                        <ul>
                          <li><a routerLink="#">Ankara</a></li>
                          <li><a routerLink="#">Kente</a></li>
                          <li><a routerLink="#">Adire</a></li>
                          <li><a routerLink="#">Aso oke</a></li>
                          <li><a routerLink="#">Ukara Ekpe</a></li>
                          <li><a routerLink="#">Kitenge</a></li>
                          <li><a routerLink="#">Kikoy</a></li>
                          <li><a routerLink="#">Kanga</a></li>
                          <li><a routerLink="#">Shweshwe</a></li>
                          <li><a routerLink="#">Prints</a></li>
                        </ul>
                      </div>

                      <div class="menuwrap">
                        <h4></h4>
                        <ul>
                          <li><a routerLink="#"></a></li>
                          <li><a routerLink="#"></a></li>
                          <li><a routerLink="#"></a></li>
                        </ul>
                      </div>
                      <div class="menuwrap">
                        <h4></h4>
                        <ul>
                          <li><a routerLink="#"></a></li>
                          <li><a routerLink="#"></a></li>
                          <li><a routerLink="#"></a></li>
                        </ul>
                      </div>
                    </div>
                    <div class="submenuItem">
                      <h3>Accessories</h3>
                      <div class="menuwrap">
                        <h4>Jewelry</h4>
                        <ul>
                          <li><a routerLink="#">Necklaces</a></li>
                          <li><a routerLink="#">Rings</a></li>
                          <li><a routerLink="#">Earrings</a></li>
                          <li><a routerLink="#">Bracelets</a></li>
                          <li><a routerLink="#">Body Jewelry</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </li>
              <li><a routerLink="#">Sale</a></li>
              <li><a routerLink="#">Brands</a></li>
            </ul>

          </div>
        </nav>
        <div class="searchProCartCurr">
          <div class="searchProCart">
            <div class="search">
              <input type="text" placeholder="Search..">
              <button type="button">
                <mat-icon>search</mat-icon>
              </button>
            </div>
            <div class="profile">
              <button type="button">
                <mat-icon>perm_identity</mat-icon>
              </button>
            </div>
            <div class="cart">
              <button type="button">
                <mat-icon>shopping_cart</mat-icon>
                <span class="count">0</span>
              </button>
            </div>
          </div>


        </div>

      </div>
      <div class="currency">
        <label>Currency</label>
        <amp-img src="./assets/images/ghana.png" alt="" height="16" width="24"></amp-img>
        <select>
          <option>Ghana</option>
        </select>
      </div>
    </div>
  </div>
</header>
import { EventService } from './event.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@envconfig';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})


export class ApiService {

  private BASE_API_URL = environment.BASE_API_URL;
  httpOptions: { headers: HttpHeaders; };
  TOKEN: string;

  constructor(
    private http: HttpClient,
    private event: EventService,
    private storage: StorageService
  ) {

    this.event.isLogin.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.TOKEN = this.storage.getDataField('token');
        this.setHeader(this.TOKEN);
      } else {
        this.setHeader('false');
      }
    });
  }

  async setHeader(TOKEN: string): Promise<any> {
    if (TOKEN !== 'false') {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
          'x-access-token': TOKEN
        })
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
        })
      };
    }
  }



  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.BASE_API_URL}${path}`, { headers: this.httpOptions.headers, params })
      .pipe(catchError(this.formatErrors));
  }

  post(path: any, body: object = {}, reportProgress = false): Observable<any> {
    return this.http.post(`${this.BASE_API_URL}${path}`, body, { headers: this.httpOptions.headers, reportProgress, })
      .pipe(catchError(this.formatErrors));
  }

  postMultiData(path: string, file: FormData, reportProgress = false): Observable<any> {
    const httpOptionsimg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        'x-access-token': this.TOKEN
      }),
      reportProgress
    };
    return this.http.post(`${this.BASE_API_URL}${path}`, file, httpOptionsimg)
      .pipe(catchError(this.formatErrors));
  }

  postMultiDatastring(path: string, file: FormData, reportProgress = false): Observable<any> {
    const httpOptionsimg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        'x-access-token': ''
      }),
      reportProgress
    };
    return this.http.post(`${this.BASE_API_URL}${path}`, file, httpOptionsimg)
      .pipe(catchError(this.formatErrors));
  }


  alert(message: string, type: any, duraion?: number): any {
    return Swal.fire({
      title: message,
      icon: type,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: duraion ? duraion : 5000,
    });
  }

  alertModal(titlec: string, message: string, type: any, CancelButton = false, isIcon?: boolean, confirmButtonTexts?: string): any {
    return Swal.fire({
      title: titlec,
      text: message,
      icon: isIcon ? type : null,
      showConfirmButton: true,
      showCancelButton: CancelButton,
      confirmButtonText: confirmButtonTexts ? confirmButtonTexts : 'Discard Changes',
      cancelButtonText: 'Stay on page'
    });
  }


  private formatErrors(error: any): any {
    return throwError(error.error);
  }
}

import { Step7Component } from './step7/step7.component';
import { Step8Component } from './step8/step8.component';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { OrderListingComponent } from './order-listing/order-listing.component';
import { VendorComponent } from './vendor.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddProductComponent } from './add-product/add-product.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { TemplatesComponent } from './templates/templates.component';
import { ContactsComponent } from './contacts/contacts.component';
import { TemplateAddComponent } from './template-add/template-add.component';
import { TemplateEditComponent } from './template-edit/template-edit.component';
import { ContactAddComponent } from './contact-add/contact-add.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';
import { TemplateViewComponent } from './template-view/template-view.component';
import { DirtyCheckGuard } from '@project/services/auth.guard';
import { OrderDetailsComponent } from './order-details/order-details.component';

const routes: Routes = [
  {
    path: '', component: VendorComponent,
    children: [
      { path: 'profile', component: ProfileComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'order-management', component: OrderListingComponent },
      { path: 'product-listing', component: ProductListingComponent },
      { path: 'add-product', component: AddProductComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'edit-product/:id', component: EditProductComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'step-8', component: Step8Component },
      { path: 'step-7', component: Step7Component },
      { path: 'templates', component: TemplatesComponent },
      { path: 'template-add', component: TemplateAddComponent },
      { path: 'template-edit/:id', component: TemplateEditComponent },
      { path: 'template-view/:id', component: TemplateViewComponent },
      { path: 'contacts', component: ContactsComponent },
      { path: 'contact-add', component: ContactAddComponent },
      { path: 'contact-edit/:id', component: ContactEditComponent },
      { path: 'order-details/:id', component: OrderDetailsComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorRoutingModule { }

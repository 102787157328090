<div class="headerVendor">
  <h1>Reset your password</h1>
  <app-vendor-header></app-vendor-header>
</div>
<div class="profileEdit bg-white rounded  p-3">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Current Password</mat-label>
          <input matInput type="password" formControlName="currentPassword">
          <mat-error
            *ngIf="form.controls.currentPassword.touched && form.controls.currentPassword.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>New Password</mat-label>
          <input matInput type="password" formControlName="newPassword">
          <mat-error *ngIf="form.controls.newPassword.touched && form.controls.newPassword.hasError('required')">
            This is required
          </mat-error>
          <mat-error *ngIf="form.controls.newPassword.touched && form.controls.newPassword.hasError('minlength')">
            Password must be at least 6 characters
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-between align-items-center bottomBtns">
      <button class="btn btn-deep" (click)="submit();">Reset</button>

    </div>
  </form>
</div>
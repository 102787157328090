import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})
export class TemplateViewComponent implements OnInit {

  details: any;

  constructor(
    private api: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(res => {
        this.getDetails(res.id)
    })
  }

  getDetails(id: any) {
    this.api.get(`/vendorportal/sendinblue/template-details/${id}`).subscribe(res => {
      this.details = res.data;
    }, err => {
      this.api.alert(err.message, 'error');
    })
  }

  sendMail() {
    const formData: any = new FormData;
    formData.append('tid', this.details.id)
    formData.append('subject', this.details.subject)
    formData.append('sender_email', this.details.sender.email)
    formData.append('sender_name', this.details.sender.name)
    this.api.postMultiData(`/vendorportal/sendinblue/mail-send`, formData).subscribe(res => {
        this.api.alert(res.message, 'success') 
        this.router.navigateByUrl('/vendor/templates');
    }, err => {
      this.api.alert(err.message, 'error')
    })
  }

}

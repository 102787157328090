<header>
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-auto logo">
                <a routerLink="/"><img src="assets/images/logo.png" alt="Logo"></a>
            </div>
            <div class="col-auto">
                <a class="btn btn-deep px-4 text-uppercase" routerLink="/vendor/add-product">Open your shop</a>
            </div>
        </div>
    </div>
</header>

<section class="hero">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center" [innerHTML]="pageData?.heading">
                    <!-- <span>Lorem Ipsum is simply dummy text of</span>
                    the printing and typesetting industry -->
                </h1>
                <figure>
                    <img draggable="false" src="assets/images/grp3.png" alt="">
                </figure>
            </div>
        </div>
    </div>
</section>

<section class="pointWrap">
    <div class="container">
        <div class="row">
            <div class="col-md-4 text-center mb-5">
                <figure>
                    <!-- <img draggable="false" src="assets/images/point1.png" alt=""> -->
                    <img src="{{baseImgPath}}{{pageData?.point_one?.image}}"
                        onError="this.src='assets/images/point1.png';" alt="" />
                </figure>
                <h3>{{pageData?.point_one?.heading}}</h3>
                <h5 [innerHTML]="pageData?.point_one?.content"></h5>
            </div>
            <div class="col-md-4 text-center mb-5">
                <figure>
                    <!-- <img draggable="false" src="assets/images/point2.png" alt=""> -->
                    <img src="{{baseImgPath}}{{pageData?.point_two?.image}}"
                        onError="this.src='assets/images/point2.png';" alt="" />
                </figure>
                <h3>{{pageData?.point_two?.heading}}</h3>
                <h5 [innerHTML]="pageData?.point_two?.content"></h5>
            </div>
            <div class="col-md-4 text-center mb-5">
                <figure>
                    <!-- <img draggable="false" src="assets/images/point3.png" alt=""> -->
                    <img src="{{baseImgPath}}{{pageData?.point_three?.image}}"
                        onError="this.src='assets/images/point3.png';" alt="" />
                </figure>
                <h3>{{pageData?.point_three?.heading}}</h3>
                <h5 [innerHTML]="pageData?.point_three?.content"></h5>
            </div>

            <div class="col-md-12 text-center  ">
                <a class="btn btn-deep px-4 text-uppercase" routerLink="/vendor/add-product">Open your shop</a>
            </div>

        </div>
    </div>
</section>


<section class="sellerStories">
    <div class="container">
        <div class="text-center mb-5">
            <h2 class="text-center" [innerHTML]="pageData?.story_heading">
                <!-- <span>Seller</span> Stories -->
            </h2>
            <!-- <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry
            </p> -->
        </div>

        <div class="row">
            <div class="col-lg-6">
                <figure class="">
                    <!-- <img src="assets/images/st1.png" alt="" class=""> -->
                    <img src="{{baseImgPath}}{{pageData?.story_one?.image}}" onError="this.src='assets/images/st1.png';"
                        alt="" />
                </figure>
            </div>
            <div class="col-lg-6">
                <div class="cont">
                    <img src="assets/images/quotation-mark.png" alt="" class="">
                    <!-- <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining essentially unchanged.
                    </p> -->
                    <div [innerHTML]="pageData?.story_one?.content"></div>
                    <!-- <h4><b>Jin</b> from Ghana</h4> -->
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 order-lg-1">
                <figure class="">
                    <!-- <img src="assets/images/st2.png" alt="" class=""> -->
                    <img src="{{baseImgPath}}{{pageData?.story_two?.image}}" onError="this.src='assets/images/st1.png';"
                        alt="" />
                </figure>
            </div>
            <div class="col-lg-6">
                <div class="cont">
                    <!-- <img src="assets/images/quotation-mark.png" alt="" class=""> -->
                    <!-- <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining essentially unchanged.
                    </p>
                    <h4><b>Roy</b> from Ethiopia</h4> -->
                    <div [innerHTML]="pageData?.story_two?.content"></div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <figure class="">
                    <!-- <img src="assets/images/st3.png" alt="" class=""> -->
                    <img src="{{baseImgPath}}{{pageData?.story_three?.image}}"
                        onError="this.src='assets/images/st1.png';" alt="" />
                </figure>
            </div>
            <div class="col-lg-6">
                <div class="cont">
                    <img src="assets/images/quotation-mark.png" alt="" class="">
                    <!-- <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining essentially unchanged.
                    </p>
                    <h4><b>Danny</b> from Ghana</h4> -->
                    <div [innerHTML]="pageData?.story_three?.content"></div>
                </div>
            </div>
        </div>

    </div>
</section>


<section class="whatCanSellWrap">
    <div class="container">
        <div class="text-center mb-5">
            <!-- <h1 class="text-center">
                <span>What Can</span> You Sell?
            </h1>
            <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry
            </p> -->
            <h2 class="text-center" [innerHTML]="pageData?.sell_heading"></h2>
        </div>

        <div class="row justify-content-between">
            <div class="col-md-4 col-xl-auto">
                <div class="whatSellBox">
                    <figure>
                        <!-- <img src="assets/images/wts1.png" alt=""> -->
                        <img src="{{baseImgPath}}{{pageData?.sell_one?.image}}"
                            onError="this.src='assets/images/wts1.png';" alt="" />
                    </figure>
                    <h4>{{pageData?.sell_one?.heading}}</h4>
                </div>
            </div>
            <div class="col-md-4 col-xl-auto">
                <div class="whatSellBox">
                    <figure>
                        <!-- <img src="assets/images/wts2.png" alt=""> -->
                        <img src="{{baseImgPath}}{{pageData?.sell_two?.image}}"
                            onError="this.src='assets/images/wts1.png';" alt="" />
                    </figure>
                    <h4>{{pageData?.sell_two?.heading}}</h4>
                </div>
            </div>
            <div class="col-md-4 col-xl-auto">
                <div class="whatSellBox">
                    <figure>
                        <!-- <img src="assets/images/wts3.png" alt=""> -->
                        <img src="{{baseImgPath}}{{pageData?.sell_three?.image}}"
                            onError="this.src='assets/images/wts1.png';" alt="" />
                    </figure>
                    <h4>{{pageData?.sell_three?.heading}}</h4>
                </div>
            </div>
        </div>

        <div class=" row">
            <div class="col-md-8 text-center  text-md-left" [innerHTML]="pageData?.description">
                <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s.</p> -->

            </div>
            <div class="col-md-4 text-center  text-md-right">
                <a class="btn btn-deep px-4 text-uppercase" routerLink="/vendor/add-product">Open your shop</a>
            </div>
        </div>
    </div>
</section>


<section class="copyWrap">
    © {{currentYear}} Shopdrop. All Rights Reserved.
</section>
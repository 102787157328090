import { Component, OnInit } from '@angular/core';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  templateData: any = [];
  loader: boolean;

  constructor(
    public api: ApiService
  ) { }

  ngOnInit(): void {
    this.getTemplates();
  }

  getTemplates() {
    this.templateData = []
     this.api.get(`/vendorportal/sendinblue/template-list`).subscribe(res => {
            res.data.map((tm: any) => {
              res.template_id_list.map((tmId: any) => {
                if(tm.id === Number(tmId)) {
                  this.templateData.push(tm)
                }
              })
            })
     }, err => {
      this.api.alert(err.message, 'error');
     })
  }

  deleteTemplate(id: any) {
    this.api.alertModal('Are you sure ?', 'You will not be able to revert this!', 'warning' , true, true).then((result: any) => {
      if(result.isConfirmed) {
        this.api.get(`/vendorportal/sendinblue/template-delete/${id}`).subscribe(res => {
          this.api.alert(res.message, 'success')
          this.getTemplates();
        }, err => {
          this.api.alert(err.message, 'success')
        })
      }
    })
  }

}

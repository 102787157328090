<div class="loginWrap">
  <div class="mClose text-right">
    <button class="btn shadow-none p-1 bg-transparent text-dark " mat-dialog-close>
      <i class="material-icons m-0 align-middle">close</i>
    </button>
  </div>
  <div class="modalInner" [formGroup]="forgotForm">
    <div class="logoOnModal text-center mb-5"><img src="assets/images/vendorLogo.png" alt=""></div>

    <div class="w-100 mb-sm-4">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>EMAIL</mat-label>
        <input matInput placeholder="john.doe@gmail.com" formControlName="email">
        <mat-error *ngIf="forgotForm.controls.email.touched && forgotForm.controls.email.hasError('required')">
          This is required
        </mat-error>
        <mat-error *ngIf="forgotForm.controls.email.touched && forgotForm.controls.email.hasError('pattern')">
          Please enter a valid email
        </mat-error>
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
      <div class="col-md-12 mb-5">
        <button class="d-block mx-auto btn btn-deep" (click)="save();">SUBMIT</button>
      </div>
    </div>
    <!-- <p class="lastLine text-center">
        Don’t have an account? <a (click)="onClickOpenRegister()">Sign Up</a>
      </p> -->
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-contact-add',
  templateUrl: './contact-add.component.html',
  styleUrls: ['./contact-add.component.scss']
})
export class ContactAddComponent implements OnInit {

  addContactForm: FormGroup;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.addContactForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
  }

  addContact() {
    if(this.addContactForm.valid){
        const data = {
          email: this.addContactForm.value.email,
          firstName: this.addContactForm.value.firstName,
          lastName: this.addContactForm.value.lastName,
        }
        this.api.post(`/vendorportal/sendinblue/contact-insert`, data).subscribe(res => {
            this.api.alert(res.message, 'success')
            this.router.navigateByUrl('/vendor/contacts')
        }, err => {
          this.api.alert(err.message, 'error')
        })
    } else {
      this.api.alert('Please check the field properly', 'error')
    }
  }

}

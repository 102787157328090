import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-forgot-dialog-step2',
  templateUrl: './forgot-dialog-step2.component.html',
  styleUrls: ['./forgot-dialog-step2.component.scss']
})
export class ForgotDialogStep2Component implements OnInit {
  forgotForm: FormGroup;
  userId: any;

  constructor(
    private api: ApiService,
    private router: Router,
    private activateRoute: ActivatedRoute,
  ) {
    this.forgotForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(params => {
      this.userId = params.id;
    });
  }

  closeDialog() {
    // this.dialogRef.close(true);
    this.router.navigate(['/vendor-home']);
  }

  updatePassword() {
    if (this.forgotForm.valid) {
      if (this.userId) {
        const payLoad = {
          password: this.forgotForm.value.password,
          authCode: this.userId
        };
        this.api.post(`/vendorportal/user/updatepassword`, payLoad).subscribe((resp: any) => {
          if (resp.status === 200) {
            this.api.alert(resp.message, 'success');
            this.closeDialog();
          } else {
            this.api.alert(resp.message, 'error');
          }
        });
      } else {
        this.api.alert('User Id Is not available', 'error');
      }
    } else {
      this.forgotForm.markAllAsTouched();
    }
  }
}

<div class="d-flex align-items-center rightSec">
    <!-- <div class="searchBox">
        <div class="d-flex">
            <input type="text" class="" placeholder="Search">
            <button class="btn">
                <i class="material-icons align-middle">
                    search
                </i>
            </button>
        </div>
    </div> -->
    <div class="notification">
        <i class="material-icons align-middle">
            notifications
        </i>
        <span>0</span>
    </div>
    <div class="user position-relative">
        <i class="material-icons align-middle" (click)="openSubmenu()">
            person
        </i>
        <div *ngIf="subMenu">
            <div class="subMenu">
                <ul class="list-unstyled">
                    <li><a routerLink="/vendor/profile" class=""><i class="material-icons"> person </i> <span>My
                                Profile</span></a></li>
                    <li><a routerLink="/vendor/change-password" class=""><i class="material-icons"> lock </i><span>Reset
                                Password</span></a></li>
                    <li><a (click)="logout()" class=""><i class="material-icons"> logout </i> <span>Logout</span></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
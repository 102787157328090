import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { ForgotDialogComponent } from '../forgot-dialog/forgot-dialog.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm:FormGroup;
  
  constructor(
    public dialogRef: MatDialogRef<LoginComponent>, public dialog: MatDialog,
    private api:ApiService,
    private storage:StorageService,
    private event: EventService,
    private router: Router,
    private cookie: CookieService) 
    { 
   this.formInit();
      
    }

  ngOnInit(): void {
    if(sessionStorage.getItem('remember')){
      this.loginForm.patchValue({
        "email":sessionStorage.getItem('username'),
        "password":sessionStorage.getItem('password'),
        "isRemembered":sessionStorage.getItem('remember')
      })
    }
  }

  formInit(){
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      password: new FormControl('', [Validators.required]),
      isRemembered:new FormControl('',),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onClickOpenRegister(): void {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '1110px',
      // height: '670px',
      maxHeight: '80vh',
      disableClose:true
    });
  }


  userLogin(){
    if (this.loginForm.valid) {
      let data={
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value
      }; 

      this.api.post(`/vendorportal/user/login`, data).subscribe((res: any) => {
        if (res.status === 200) {
            let data={
              token: res.token,
              vendorId: res.data._id
            };
            this.storage.setUser(data).then(() => {
              this.event.setLoginEmmit(true);
              if(this.loginForm.value.isRemembered===true){
                sessionStorage.setItem('username',this.loginForm.value.email);
                sessionStorage.setItem('password',this.loginForm.value.password);
                sessionStorage.setItem('remember',this.loginForm.value.isRemembered);
              }else{
                sessionStorage.clear();
              }
              
              if(data.token){
                this.router.navigate(['/vendor/order-management']);
                this.onNoClick();
              }
            });
            this.api.alert('Login Successful', 'success');
        } else if(res.status===201){
          this.api.alert(res.message, 'warning');
        }else{
          this.api.alert(res.message, 'error');
        }
      }, err=>{
        this.api.alert('Wrong email or password', 'error');
      });
  } else {
    this.loginForm.markAllAsTouched();
  }
  }
  forgotPassword() {
    const dialogRef = this.dialog.open(ForgotDialogComponent,{
      width: '670px',
      // height: '670px',
      maxHeight: '80vh',
      disableClose:true
    });
  }
}

<div class="loginWrap">
  <div class="mClose text-right">
    <button class="btn shadow-none p-1 bg-transparent text-dark " mat-dialog-close>
      <i class="material-icons m-0 align-middle">close</i>
    </button>
  </div>
  <div class="modalInner" [formGroup]="loginForm">
    <div class="logoOnModal text-center mb-5"><img src="assets/images/vendorLogo.png" alt=""></div>

    <div class="w-100 mb-sm-4">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>EMAIL</mat-label>
        <input matInput placeholder="john.doe@gmail.com" formControlName="email">
        <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.hasError('required')">
          Email is required
        </mat-error>
        <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.hasError('pattern')">
          Please enter a valid email
        </mat-error>
      </mat-form-field>
    </div>
    <div class="w-100 mb-sm-4">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>PASSWORD</mat-label>
        <input type="password" matInput placeholder="Password" formControlName="password">
        <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.hasError('required')">
            Password is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="w-100 d-sm-flex justify-content-between align-items-center">
      <label class="remembar d-inline-flex align-items-center">
        <input type="checkbox" name="" id="" hidden formControlName="isRemembered">
        <i></i> <span class="pl-2">Remember me</span></label>
      <div class="forgotPass">
        <a (click)="forgotPassword()" [mat-dialog-close]="true">Forgot Password?</a>
      </div>
    </div>
    <div class="loginBtns text-center py-5">
      <button class="btn btn-deep" (click)="userLogin()" [disabled]="loginForm.invalid">LOGIN</button>
    </div>
    <p class="lastLine text-center">
      Don’t have an account? <a (click)="onClickOpenRegister()">Sign Up</a>
    </p>
  </div>
</div>
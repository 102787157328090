import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-step8',
  templateUrl: './step8.component.html',
  styleUrls: ['./step8.component.scss']
})
export class Step8Component implements OnInit {
  foods = [
    { value: '0', viewValue: 'Data 1' },
    { value: '1', viewValue: 'Data 2' },
    { value: '2', viewValue: 'Data 3' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
  
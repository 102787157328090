import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-step7',
  templateUrl: './step7.component.html',
  styleUrls: ['./step7.component.scss']
})
export class Step7Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="headerVendor">
    <h1>Contacts</h1>
    <div class="">
        <app-vendor-header></app-vendor-header>
    </div>
</div>


<div class="order-Head">
    <div class="inner">
        <h5 class="">Contact Listing</h5>
        <p class="">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    </div>
</div>

<div class="orderTable">
    <div class="table-responsive">
        <div class="btns">
            <a href="assets/csv/format.csv" download>Download CSV</a>
            <label>
                Upload CSV
                <input type="file" (change)="uploadCSV(CSVFILE)" #CSVFILE hidden accept=".csv" />
            </label>
            
            <button [routerLink]="['/vendor/contact-add']">+ Add New Contact</button>
        </div>
        <table class="table">
            <thead>
                <th>Email</th>
                <th>Action</th>
            </thead>
            <tbody>
               <tr *ngFor="let item of contactData">
                   <td>{{item?.email}}</td>
                   <td>
                    <a [routerLink]="['/vendor/contact-edit/' + item?.id]"><i class="material-icons">edit</i></a>
                    <a (click)="deleteContact(item?.id)"><i class="material-icons">delete</i></a>
                </td>
               </tr>
            </tbody>
        </table>
        <!-- <ng-container *ngIf="tableData.length > 0">
            <pagination-controls (pageChange)="getPrductList($event)" id="server"></pagination-controls>
        </ng-container> -->
    </div>
</div>
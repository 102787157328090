import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';

@Component({
  selector: 'app-vendor-header',
  templateUrl: './vendor-header.component.html',
  styleUrls: ['./vendor-header.component.scss']
})
export class VendorHeaderComponent implements OnInit {
  subMenu: boolean;

  constructor(
    private storage: StorageService,
    private router: Router,
    private event: EventService,
  ) { }

  ngOnInit(): void {
  }

  openSubmenu(): void {
    this.subMenu = !this.subMenu;
  }

  logout() {
    this.storage.clearUser();
    this.router.navigateByUrl('/vendor-home');
    this.event.setLoginEmmit(false);
  }

}

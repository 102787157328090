import { Step7Component } from './step7/step7.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MaterialModule } from '@project/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VendorRoutingModule } from './vendor-routing.module';
import { VendorComponent } from './vendor.component';
import { VendorHeaderComponent } from './vendor-header/vendor-header.component';
import { OrderListingComponent } from './order-listing/order-listing.component';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { AddProductComponent } from './add-product/add-product.component';
import { Step8Component } from './step8/step8.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxEditorModule } from 'ngx-editor';
import { ProfileComponent } from './profile/profile.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { TemplatesComponent } from './templates/templates.component';
import { ContactsComponent } from './contacts/contacts.component';
import { TemplateAddComponent } from './template-add/template-add.component';
import { TemplateEditComponent } from './template-edit/template-edit.component';
import { ContactAddComponent } from './contact-add/contact-add.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { TemplateViewComponent } from './template-view/template-view.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ImageCropperModule } from 'ngx-image-cropper';
import {NgxImageCompressService} from 'ngx-image-compress';
import { EditHelpComponent } from './add-product/edit-help/edit-help.component';
import { OrderDetailsComponent } from './order-details/order-details.component';

@NgModule({
  declarations: [
    VendorComponent, 
    VendorHeaderComponent, 
    OrderListingComponent, 
    ProductListingComponent, 
    AddProductComponent, 
    Step8Component, 
    Step7Component, 
    ProfileComponent, 
    ChangePasswordComponent, 
    EditProductComponent, 
    TemplatesComponent, 
    ContactsComponent, 
    TemplateAddComponent, 
    TemplateEditComponent, 
    ContactAddComponent, 
    ContactEditComponent, 
    TemplateViewComponent, EditHelpComponent, OrderDetailsComponent
  ],
  imports: [
    CommonModule,
    VendorRoutingModule,
    MaterialModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxEditorModule,
    NgxMatIntlTelInputModule,
    CKEditorModule,
    ImageCropperModule,
  ], 
  providers: [
    NgxImageCompressService
  ]
})
export class VendorModule { }

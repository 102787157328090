import { Component, OnInit } from '@angular/core';
import { ApiService } from '@project/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@envconfig';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  imageSrc: string;
  countries: any;
  form: FormGroup;
  businesstypes: any;
  verificationDocumentSrc: string;
  idDocumentSrc: string;
  baseImgPath: string;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
  ) {
    this.baseImgPath = environment.IMAGE_BASE_URL;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+')]],
      email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      mobile: ['', [Validators.required]],
      country: ['', [Validators.required]],
      vendorType: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      businessRegistrationNumber: ['', [Validators.required]],
      businessAddress: ['', [Validators.required]],
      primaryContactPerson: ['', [Validators.required]],
      verificationDocumentType: ['', [Validators.required]],
      verificationDocument: ['', [Validators.required]],
      publicStoreName: ['', [Validators.required]],
      idDocumentType: ['', [Validators.required]],
      idDocument: ['', [Validators.required]],
      profileImage: [''],
    });

    this.getBusinessTypes();
    this.getCountries();
    this.getProfile();
  }

  getProfile() {
    this.apiService.post(`/vendorportal/user/myprofile`).subscribe((res: any) => {
      if (res) {
        console.log(res.data.vendorData.businessRegistrationNumber);
        this.form.patchValue(res.data);
        this.form.patchValue(res.data.vendorData);
        // this.form.patchValue({ vendorType: ['6078368ec6471c1b24b78d37', '6079680764fd7022388a8a7d'] });
        // this.form.patchValue({
        //   businessName: res.data.vendorData.businessName,
        //   businessRegistrationNumber: res.data.vendorData.businessRegistrationNumber,
        //   businessAddress: res.data.vendorData.businessAddress,
        //   primaryContactPerson: res.data.vendorData.primaryContactPerson,
        //   publicStoreName: res.data.vendorData.publicStoreName,
        //   businessType: res.data.vendorData.vendorType,
        // });
        // this.form.patchValue({
        //   businessType: res.data.vendorType,
        // });
        this.verificationDocumentSrc = res.data.vendorData.verificationDocument.split('_')[2];
        this.idDocumentSrc = res.data.vendorData.idDocument.split('_')[2];
        this.imageSrc = this.baseImgPath + '/user/' + res.data.profileImage;
      }
    });
  }

  onFileChange(event: any, type: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].type !== 'image/svg+xml') {
        if (event.target.files[0].type.split('/')[0] === 'image') {
          const [file] = event.target.files;
          reader.readAsDataURL(file);
          if (type === 'profileImg') {
            reader.onload = () => {
              this.imageSrc = reader.result as string;
              this.form.patchValue({
                profileImage: event.target.files[0]
              });

            };
          }
          if (type === 'uploadverificationDocument') {
            reader.onload = () => {
              this.verificationDocumentSrc = event.target.files[0].name;
              this.form.patchValue({
                verificationDocument: event.target.files[0]
              });

            };
          }
          if (type === 'uploadIdDocument') {
            reader.onload = () => {
              this.idDocumentSrc = event.target.files[0].name;
              this.form.patchValue({
                idDocument: event.target.files[0]
              });

            };
          }
        } else {
          this.apiService.alert('Invalid File Format', 'error');
        }
      } else {
        this.apiService.alert('Invalid File Format', 'error');
      }
    }
  }

  getCountries() {
    this.apiService.get(`/vendorportal/masterdata/countries`).subscribe((res: any) => {
      this.countries = res.data;
    });
  }

  getBusinessTypes() {
    this.apiService.get(`/vendorportal/masterdata/vendortypes`).subscribe((res: any) => {
      this.businesstypes = res.data;
    });
  }

  submit() {
    if (this.form.valid) {
      console.log(this.form.value.businessType);
      const formData: any = new FormData();
      formData.append('name', this.form.value.name);
      formData.append('email', this.form.value.email);
      formData.append('mobile', this.form.value.mobile);
      formData.append('country', this.form.value.country);
      formData.append('vendorType', this.form.value.vendorType);
      // formData.append('businessType', this.form.value.businessType);
      formData.append('businessName', this.form.value.businessName);
      formData.append('businessRegistrationNumber', this.form.value.businessRegistrationNumber);
      formData.append('businessAddress', this.form.value.businessAddress);
      formData.append('primaryContactPerson', this.form.value.primaryContactPerson);
      formData.append('publicStoreName', this.form.value.publicStoreName);
      formData.append('verificationDocumentType', this.form.value.verificationDocumentType);
      formData.append('verificationDocument', this.form.value.verificationDocument);
      formData.append('idDocumentType', this.form.value.idDocumentType);
      formData.append('idDocument', this.form.value.idDocument);
      formData.append('profileImage', this.form.value.profileImage);

      this.apiService.postMultiData(`/vendorportal/user/updateprofile`, formData).subscribe((res: any) => {
        if (res.status === 200) {
          // this.getProfile();
          this.apiService.alert(res.message, 'success');
        } else {
          this.apiService.alert(res.message, 'error');
        }
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { Editor } from 'ngx-editor';


@Component({
  selector: 'app-template-add',
  templateUrl: './template-add.component.html',
  styleUrls: ['./template-add.component.scss']
})
export class TemplateAddComponent implements OnInit {

  editor: Editor;
  addTemplateForm: FormGroup;
  
  editorConfig = {
    // plugins: [Underline, Strikethrough, Code],
    toolbar: {
      items: [
          'heading', '|', 'bold', 'italic', 'link', 'underline', 'strikethrough', 'code',
          'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|',
          'blockQuote',
          'undo', 'redo',
          'htmlEmbed'
      ]
  },
  }

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.addTemplateForm = this.fb.group({
      templateName: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      mailBody: ['', [Validators.required]],
    });
  }

  addTemplate() {
    if(this.addTemplateForm.valid){
        const data = {
          templateName: this.addTemplateForm.value.templateName,
          subject: this.addTemplateForm.value.subject,
          mailBody: this.addTemplateForm.value.mailBody,
        }
        this.api.post(`/vendorportal/sendinblue/template-insert`, data).subscribe(res => {
            this.api.alert(res.message, 'success')
            this.router.navigateByUrl('/vendor/templates')
        })
    } else {
      this.api.alert('All fields are required', 'error')
    }
  }

}

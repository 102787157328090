<div class="headerVendor">
  <h1>Profile</h1>
  <app-vendor-header></app-vendor-header>
</div>

<div class="profileEdit bg-white rounded shadow-lg p-3">
  <form [formGroup]="form">
    <div class="row mx-0">
      <div class="col-12 mb-4">
        <h5 class="mb-3">Profile Image</h5>
        <div class="imgUpBoxRow">
          <label class="imgBox shadow border">
            <img [src]="imageSrc" onError="this.src='assets/images/add_photo_alternate_black_24dp.svg';" alt="" />
            <input type="file" name="image" id="image" hidden (change)="onFileChange($event, 'profileImg')"
              accept="image/*" />
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" formControlName="name">
          <mat-error *ngIf="form.controls.name.touched && form.controls.name.hasError('required')">
            This is required
          </mat-error>
          <mat-error *ngIf="form.controls.name.touched && form.controls.name.hasError('pattern')">
            Please enter a valid name
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email">
          <mat-error *ngIf="form.controls.email.touched && form.controls.email.hasError('required')">
            This is required
          </mat-error>
          <mat-error *ngIf="form.controls.email.touched && form.controls.email.hasError('pattern')">
            Please enter a valid email
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Phone Number</mat-label>
          <!-- <input matInput placeholder="Phone Number" formControlName="mobile" > -->
          <ngx-mat-intl-tel-input [enablePlaceholder]="true" [enableSearch]="true" name="mobile"
            inputPlaceholder="Phone Number" formControlName="mobile">
          </ngx-mat-intl-tel-input>
          <mat-error *ngIf="form.controls.mobile.touched && form.controls.mobile.hasError('required')">
            This is required
          </mat-error>
          <mat-error *ngIf="form.controls['mobile']?.errors?.validatePhoneNumber">Invalid Number
          </mat-error>
          <!-- <mat-error
            *ngIf="form.controls.mobile.touched && (form.controls.mobile.hasError('minlength') || form.controls.mobile.hasError('maxlength'))">
            Phone number must be 8 to 16 digits.
          </mat-error> -->
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Country</mat-label>
          <mat-select formControlName="country">
            <mat-option *ngFor="let country of countries" [value]="country._id">
              {{country.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.country.touched && form.controls.country.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>


      <div class="col-md-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Business Type</mat-label>
          <mat-select formControlName="vendorType" multiple>
            <mat-option *ngFor="let vendor of businesstypes" [value]="vendor._id">
              {{vendor.title}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.vendorType.touched && form.controls.vendorType.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Business Name</mat-label>
          <input matInput placeholder="Business Name" formControlName="businessName">
          <mat-error *ngIf="form.controls.businessName.touched && form.controls.businessName.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Business Registration Number</mat-label>
          <input matInput placeholder="Business Registration Number" formControlName="businessRegistrationNumber">
          <mat-error
            *ngIf="form.controls.businessRegistrationNumber.touched && form.controls.businessRegistrationNumber.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Primary Contact Person</mat-label>
          <input matInput placeholder="Primary Contact Person" formControlName="primaryContactPerson">
          <mat-error
            *ngIf="form.controls.primaryContactPerson.touched && form.controls.primaryContactPerson.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Business Address</mat-label>
          <input matInput placeholder="Business Address" formControlName="businessAddress">
          <mat-error
            *ngIf="form.controls.businessAddress.touched && form.controls.businessAddress.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Public Store Name</mat-label>
          <input matInput placeholder="Public Store Name" formControlName="publicStoreName">
          <mat-error
            *ngIf="form.controls.publicStoreName.touched && form.controls.publicStoreName.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Upload any Document verifying</mat-label>
          <mat-select formControlName="verificationDocumentType">
            <mat-option value="nationalIdcard">National Identity Card
            </mat-option>
            <mat-option value="drivinglicense">Driving License
            </mat-option>
            <mat-option value="tin">TIN</mat-option>
            <mat-option value="businessPermit">Business Permit
            </mat-option>
            <mat-option value="passport">Passport</mat-option>
            <mat-option value="trulioo">Trulioo</mat-option>
          </mat-select>
          <mat-error
            *ngIf="form.controls.verificationDocumentType.touched && form.controls.verificationDocumentType.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">

        <label class="update-profile-img align-items-center d-flex  border-bottom   p-3 justify-content-between">
          <span *ngIf="!verificationDocumentSrc">Upload File </span>
          <span *ngIf="verificationDocumentSrc">{{verificationDocumentSrc}}</span>

          <i class="material-icons mr-2 mb-0">
            drive_folder_upload
          </i>
          <input type="file" multiple="false" accept="image/*"
            (change)="onFileChange($event, 'uploadverificationDocument')" type="file" #verificationId hidden>
          <mat-error
            *ngIf="form.controls.verificationDocument.touched && form.controls.verificationDocument.hasError('required')">
            This is required
          </mat-error>
        </label>
      </div>

      <div class="col-md-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Upload any ID</mat-label>
          <mat-select formControlName="idDocumentType">
            <mat-option value="nationalIdcard">National Identity Card
            </mat-option>
            <mat-option value="drivinglicense">Driving License
            </mat-option>
            <mat-option value="tin">TIN</mat-option>
            <mat-option value="businessPermit">Business Permit
            </mat-option>
            <mat-option value="passport">Passport</mat-option>
            <mat-option value="trulioo">Trulioo</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.idDocumentType.touched && form.controls.idDocumentType.hasError('required')">
            This is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <label class="update-profile-img align-items-center d-flex  border-bottom   p-3 justify-content-between">
          <span *ngIf="!idDocumentSrc">Upload File </span>
          <span *ngIf="idDocumentSrc">{{idDocumentSrc}}</span>
          <i class="material-icons mr-2 mb-0">
            drive_folder_upload
          </i>
          <input type="file" accept="image/*" (change)="onFileChange($event, 'uploadIdDocument')" type="file"
            #documentId hidden>
          <mat-error *ngIf="form.controls.idDocument.touched && form.controls.idDocument.hasError('required')">
            This is required
          </mat-error>
        </label>
      </div>
      <div class="col-12">
        <div class="w-100 d-flex justify-content-between align-items-center bottomBtns">
          <!-- <button class="btn btn-outline-deep mr-auto">BACK</button> -->
          <button class="btn btn-deep" (click)="submit()">Update</button>
        </div>
      </div>
    </div>

  </form>
</div>
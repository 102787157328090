import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss']
})
export class TemplateEditComponent implements OnInit {

  editor: Editor;
  addTemplateForm: FormGroup;
  id: any;
  templateData: any;
  templateDetails: any;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    public router: Router,
    public ar: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ar.params.subscribe((pr: any) => {
      this.id = pr.id;
    })
    this.getTemplates();
    this.editor = new Editor();
    this.addTemplateForm = this.fb.group({
      templateName: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      mailBody: ['', [Validators.required]],
    });
  }

  getTemplates() {
    this.api.get(`/vendorportal/sendinblue/template-list`).subscribe(res => {
      this.templateData = res.data;
      this.getDetails()
    }, err => {
     this.api.alert(err.message, 'error');
    })
 }

 getDetails() {
   this.templateData.map((td: any) => {
     if(td.id === Number(this.id)) {
      this.templateDetails = td;
      this.addTemplateForm.controls.templateName.setValue(this.templateDetails.name)
      this.addTemplateForm.controls.subject.setValue(this.templateDetails.subject)
      this.addTemplateForm.controls.mailBody.setValue(this.templateDetails.htmlContent)
     }
   })
 }

  addTemplate() {
    if(this.addTemplateForm.valid){
        const data = {
          id: this.id,
          templateName: this.addTemplateForm.value.templateName,
          subject: this.addTemplateForm.value.subject,
          mailBody: this.addTemplateForm.value.mailBody,
        }
        this.api.post(`/vendorportal/sendinblue/template-update`, data).subscribe(res => {
            this.api.alert(res.message, 'success')
            this.router.navigateByUrl('/vendor/templates')
        })
    } else {
      this.api.alert('All fields are required', 'error')
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-forgot-dialog',
  templateUrl: './forgot-dialog.component.html',
  styleUrls: ['./forgot-dialog.component.scss']
})
export class ForgotDialogComponent implements OnInit {
  forgotForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ForgotDialogComponent>,
    private api: ApiService
  ) {
    this.forgotForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
    });
  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    if (this.forgotForm.valid) {
      this.api.post(`/vendorportal/user/resetpassword`, this.forgotForm.value).subscribe((resp: any) => {
        if (resp.status === 200) {
          this.api.alert(resp.message, 'success');
          this.closeDialog();
        } else {
          this.api.alert(resp.message, 'warning');
        }
      });
    } else {
      this.forgotForm.markAllAsTouched();
    }

  }
}

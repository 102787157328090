<div class="headerVendor">
    <h1>Edit Template</h1>
    <app-vendor-header></app-vendor-header>
</div>

<div class="addTemplate">
    <form [formGroup]="addTemplateForm">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Template Name</mat-label>
                <input matInput formControlName="templateName">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Subject</mat-label>
                <input matInput formControlName="subject">
            </mat-form-field>
        </div>
        <div class="col-md-12">
            <!-- <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
            <ngx-editor [editor]="editor" [placeholder]="'Mail Body...'" formControlName="mailBody"></ngx-editor> -->
            <ckeditor formControlName="mailBody"></ckeditor>
        </div>
        <button (click)="addTemplate()">Update</button>
    </div>
    </form>
</div>
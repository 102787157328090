<div class="headerVendor">
    <h1>Edit Contact</h1>
    <app-vendor-header></app-vendor-header>
</div>

<div class="addTemplate">
    <form [formGroup]="addContactForm">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="legacy" class="w-100">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName">
            </mat-form-field>
        </div>
        <div class="col-md-12">
            <mat-form-field appearance="legacy" class="w-50">
                <mat-label>Contact Email</mat-label>
                <input matInput formControlName="email">
                <mat-error *ngIf="addContactForm.controls.email.touched && addContactForm.controls.email.hasError('pattern')">
                    Please enter a valid email
                </mat-error>
            </mat-form-field>
        </div>
        <button (click)="addContact()">Update</button>
    </div>
    </form>
</div>
<div class="headerVendor">
    <h1>Listing</h1>
    <div class="">
        <app-vendor-header></app-vendor-header>
    </div>
</div>


<div class="order-Head">
    <div class="inner">
        <h5 class="">Product Listing</h5>
        <p class="">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    </div>
</div>

<div class="orderTable">
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th class="border-top-0 text-nowrap">Image</th>
                    <th class="border-top-0 text-nowrap">Title</th>
                    <th class="border-top-0 text-nowrap">Product Type</th>
                    <th class="border-top-0 text-nowrap">Product Sub Type</th>
                    <th class="border-top-0 text-nowrap">Category</th>
                    <th class="border-top-0 text-nowrap">Sub Category</th>
                    <th class="border-top-0 text-nowrap">Actions</th>
                    <th class="border-top-0 text-nowrap">Featured</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="tableData.length > 0">
                    <tr
                        *ngFor="let item of tableData | paginate: { itemsPerPage: 10, currentPage: p }; let i = index;">
                        <!-- paginate: { id: 'server', itemsPerPage: pageData?.perPage, currentPage: pageData?.currentPage, totalItems: pageData?.totalItems } -->
                        <td><img src="{{imageUrl}}/product/{{item?.image}}" alt="" width="100" height="100">
                        </td>
                        <td>{{item?.title}}</td>
                        <td>{{item?.productTypes?.title}}</td>
                        <td>{{item?.SubproductTypes?.title}}</td>
                        <td>{{item?.category?.title}}</td>
                        <td class="text-nowrap">{{item?.subCategory?.title}}</td>
                        <td>
                            <a [routerLink]="['/vendor/edit-product', item?._id]"><i class="material-icons">edit</i></a>
                            <a (click)="deleteProduct(item?._id)"><i class="material-icons">delete</i></a>
                        </td>
                        <td>
                            <mat-checkbox [checked]="item?.isFeatured" (change)="selectFeatured($event, item._id)">
                            </mat-checkbox>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="tableData.length === 0">
                    <tr>No Product to display</tr>
                </ng-container>
            </tbody>
        </table>
        <ng-container *ngIf="tableData.length > 0">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
            <!-- getPrductList($event) -->
        </ng-container>
    </div>
</div>
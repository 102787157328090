<div class="breadcrumbsec">
  <div class="container">
    <div class="innerbreadcrumbsec">
      <ul>
        <li>
          <a [routerLink]="['/home']" routerLinkActive="active">Homepage</a>
        </li>
        <li *ngFor="let item of breadcrumbs; let i = index">
          <!-- <a [routerLink]="item?.url" [routerLinkActiveOptions]="{exact: true}"
          routerLinkActive="active">{{item.label}}</a> -->
          <a *ngIf="i+1 != breadcrumbs.length" [routerLink]="item?.url">{{item.label}}</a>
          <a *ngIf="i+1 === breadcrumbs.length" class="active" style="cursor:text">{{item.label}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
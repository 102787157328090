<div class="footer">
  <div class="footMail">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-5">
          <div class="footTopLft">
            <h2>Get Our Emails</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="footerMail">
            <input type="text" placeholder="email address">
            <button type="button" class="btn">Submit</button>
          </div>
        </div>
      </div>


    </div>
    <div class="backToTop">
      <button type="button" (click)="onEdit()">
        <mat-icon>arrow_upward</mat-icon>
      </button>
    </div>
  </div>
  <div class="footerBot">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3">
          <h3>QUICK LINKS</h3>
          <ul class="footLinks">
            <li><a routerLink="#">Fashion</a></li>
            <li><a routerLink="#">Sale</a></li>
            <li><a routerLink="#">Brands</a></li>
            <li><a routerLink="#">What is ShopDrop?</a></li>
            <li><a routerLink="#">Contact</a></li>
          </ul>
        </div>
        <div class="col-lg-3">
          <h3>OTHER LINKS</h3>
          <ul class="footLinks">
            <li><a routerLink="#">Terms and Conditions</a></li>
            <li><a routerLink="#">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="col-lg-3">
          <h3>CONTACT DETAILS</h3>
          <ul class="addressCallMail">
            <li>
              <mat-icon>home</mat-icon> <span> Dummy address<br> goes here, 412587</span>
            </li>
            <li>
              <mat-icon>call</mat-icon> Helpline: <a routerLink="tel:0123456789">0123-456-789</a>
            </li>
            <li>
              <mat-icon>email</mat-icon> Email: <a routerLink="mailto:">support@yourwebsite.com</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <h3>FOLLOW US</h3>
          <ul class="socialLink">
            <li><a routerLink="#"><i class="fa fa-facebook" aria-hidden="true"></i> </a></li>
            <li><a routerLink="#"><i class="fa fa-twitter" aria-hidden="true"></i>
              </a></li>
            <li><a routerLink="#"><i class="fa fa-instagram" aria-hidden="true"></i>
              </a></li>
            <li><a routerLink="#"><i class="fa fa-pinterest" aria-hidden="true"></i>
              </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
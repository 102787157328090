<div class="headerVendor">
    <h1>Add Product</h1>
    <div>
        <app-vendor-header></app-vendor-header>
    </div>
</div>


<div class="addProduct">


    <div class="progressLine pb-5">
        <ul class="list-unstyled d-flex justify-content-between">
            <li><span>1</span></li>
            <li><span>2</span></li>
            <li><span>3</span></li>
            <li><span>4</span></li>
            <li><span>5</span></li>
            <li><span routerLink="/vendor/add-product">6</span></li>
            <li class="active"><span routerLink="/vendor/step-7">7</span></li>
            <li><span>8</span></li>
        </ul>
    </div>

    <ng-container>
        <div class="h5 text-uppercase mb-5">Step 7 - IMAGES </div>

        <div class="row ">

            <div class="col-12">
                <h5 class="mb-3">Feature Image</h5>

                <div class="imgUpBoxRow">
                    <label class="imgBox">
                        <img src="assets/images/add_photo_alternate_black_24dp.svg" alt="" class="">
                        <input type="file" name="" hidden id="">
                    </label>
                </div>
            </div>

            <div class="col-12">
                <h5>Product Images</h5>

                <div class="imgUpBoxRow d-flex">
                    <label class="imgBox">
                          <img src="assets/images/add_photo_alternate_black_24dp.svg" alt="" class="">
                        <input type="file" name="" hidden id="">
                    </label>
                    <label class="imgBox">
                          <img src="assets/images/add_photo_alternate_black_24dp.svg" alt="" class="">
                        <input type="file" name="" hidden id="">
                    </label>
                    <label class="imgBox">
                        <img src="assets/images/addP4.png" alt="" class="">
                        <input type="file" name="" hidden id="">
                    </label>
                </div>
            </div>


        </div>


        <div class="w-100 d-flex justify-content-between align-items-center bottomBtns">
            <button class="btn btn-outline-deep mr-auto">BACK</button>
            <button class="btn btn-deep ml-4">NEXT</button>
        </div>
    </ng-container>

</div>
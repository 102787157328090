<div class="headerVendor">
    <h1>Add Product</h1>
    <div>
        <app-vendor-header></app-vendor-header>
    </div>
</div>


<div class="addProduct">


    <div class="progressLine pb-5">
        <ul class="list-unstyled d-flex justify-content-between">
            <li><span>1</span></li>
            <li><span>2</span></li>
            <li><span>3</span></li>
            <li><span>4</span></li>
            <li><span>5</span></li>
            <li><span routerLink="/vendor/add-product">6</span></li>
            <li><span routerLink="/vendor/step-7">7</span></li>
            <li class="active"><span>8</span></li>
        </ul>
    </div>

    <ng-container>
        <div class="h5 text-uppercase mb-5">Step 8 - Review & Submit </div>

        <div class="row ">
            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Product Type</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Sub Type
                    </mat-label>
                    <mat-select>
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Category </mat-label>
                    <input type="text" matInput value="Men’s Jacket">
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>
            <div class="col-md-6  ">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Sub category</mat-label>
                    <input type="text" matInput value="Jacket">
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>
            <div class="col-md-12 ">
                <div class="w-100 mb-4">
                    <h5 class="text-uppercase">SKU 123456</h5>
                </div>
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Product Name</mat-label>
                    <input type="text" matInput value="Men’s Attractive Leather Jacket">
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="col-md-12 ">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Product Description</mat-label>
                    <textarea type="text" rows="2" matInput
                        value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."></textarea>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Select Attributes</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>
            <div class="col-12"></div>
            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Product price</mat-label>
                    <input type="text" matInput value="USD 1200">
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Price Without Fabric</mat-label>
                    <input type="text" matInput value="USD 800">
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Ship to Designer Cost</mat-label>
                    <input type="text" matInput value="USD 100">
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>
            <div class="col-12"></div>

            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Primary Fabric</mat-label>
                    <input type="text" matInput value="Leather">
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Category</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Fabric Unit</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100 mb-4">
                    <mat-label>Available Unit Variations</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                        </mat-option>
                    </mat-select>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="col-12">
                <h5>Feature Image</h5>

                <div class="imgUpBoxRow">
                    <label class="imgBox">
                        <img src="assets/images/addP1.png" alt="" class="">
                        <input type="file" name="" hidden id="">
                    </label>
                </div>
            </div>

            <div class="col-12">
                <h5>Product Images</h5>

                <div class="imgUpBoxRow d-flex">
                    <label class="imgBox">
                        <img src="assets/images/addP2.png" alt="" class="">
                        <input type="file" name="" hidden id="">
                    </label>
                    <label class="imgBox">
                        <img src="assets/images/addP3.png" alt="" class="">
                        <input type="file" name="" hidden id="">
                    </label>
                    <label class="imgBox">
                        <img src="assets/images/addP4.png" alt="" class="">
                        <input type="file" name="" hidden id="">
                    </label>
                </div>
            </div>


        </div>


        <div class="w-100 d-flex justify-content-between align-items-center bottomBtns">
            <button class="btn btn-outline-deep mr-auto">BACK</button>
            <button class="btn btn-deep ml-4">NEXT</button>
        </div>
    </ng-container>

</div>